import VCalendar from 'v-calendar'
import type { App } from 'vue'
import type { Plugin } from '#app'
import { defineNuxtPlugin } from '#app'

const vCalendar: Plugin = defineNuxtPlugin(({ vueApp }: { vueApp: App<Element> }) => {
  vueApp.use(VCalendar)
})

export default vCalendar
